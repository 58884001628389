<template>
    <header class="page-title">
        <h1><i class="bi bi-gear"></i> ユーザ編集</h1>
        <router-link class="btn btn-outline-secondary" :to="{name: 'SystemUser'}"><i class="bi bi-reply"></i> 一覧に戻る</router-link>
    </header>

    <section class="section">
        <form @submit.prevent="save">
            <div class="row mb-3 align-items-end">
                <div class="col-6">
                    <label class="form-label">氏名</label>
                    <form-input v-model="user.user_name" required :disabled="loading"></form-input>
                </div>
                <div class="col-12">
                    <label class="form-label">メールアドレス</label>
                    <form-input v-model="user.email" required :disabled="loading"></form-input>
                </div>
                <div class="col-6">
                    <form-checkbox v-model="reset_password" option_label="パスワードをリセット" :disabled="loading"></form-checkbox>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-6">
                    <label class="form-label">在席期間</label>
                    <div class="input-group">
                        <form-input-month v-model="user.member_from" required :disabled="loading"></form-input-month>
                        <div class="input-group-text">〜</div>
                        <form-input-month v-model="user.member_to" :disabled="loading"></form-input-month>
                    </div>
                </div>
                <div class="col-6">
                    <label class="form-label">部門</label>
                    <form-select-object v-model="user.department" :options="departments" option_value="department_id" option_label="department_name" empty_option="-- 選択 --" :disabled="departments.length === 0"></form-select-object>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-24">
                    <label class="form-label">権限</label>
                    <form-checkboxes v-model="user.permissions" :options="Permission.options()" :disabled="loading"></form-checkboxes>
                </div>
            </div>
            <div class="mt-5">
                <button class="btn btn-info btn-lg" :disabled="loading">保存</button>
            </div>
        </form>
        <div class="mt-5 text-end">
            <button class="btn btn-outline-danger" type="button" @click="this.$refs.confirmRemove.show();" :disabled="loading">削除</button>
        </div>
    </section>

    <confirm-dialog ref="confirmRemove" @ok="remove">
        <p>本当に削除してよろしいですか？</p>
    </confirm-dialog>
</template>

<script>
import User from "@/models/entities/user";
import Department from '@/models/entities/department';
import Permission from '@/models/enums/permission';
import FormInput from '@/components/forms/FormInput';
import FormInputMonth from '@/components/forms/FormInputMonth';
import FormSelectObject from '@/components/forms/FormSelectObject';
import FormCheckbox from '@/components/forms/FormCheckbox';
import FormCheckboxes from '@/components/forms/FormCheckboxes';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';

export default {
    name: 'SystemUserEdit',
    components: {
        ConfirmDialog,
        FormInput,
        FormInputMonth,
        FormSelectObject,
        FormCheckbox,
        FormCheckboxes,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            loading: true,
            user_id: parseInt(this.$route.params.user),
            user: new User(),
            reset_password: false,
            departments: [],
            Permission,
        }
    },
    mounted() {
        this.$http.get(`/system/user/${this.user_id}`)
        .then((response) => {
            this.user = new User(response.data);
            this.loading = false;
        })

        this.$http.get('/list/department')
        .then((response) => {
            this.departments = response.data.map((row) => {return new Department(row)});
        })
    },
    methods: {
        save() {
            this.startScreenLoading();
            let param = Object.assign({
                reset_password: this.reset_password,
            }, this.user);

            this.$http.put(`/system/user/${this.user_id}`, param)
            .then(() => {
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        remove() {
            this.startScreenLoading();
            this.$http.delete(`/system/user/${this.user_id}`)
            .then(() => {
                this.showMessage('削除しました');
                this.$router.push({name: 'SystemUser'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    },
}
</script>

<style scoped>
</style>
